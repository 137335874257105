const data = [
  {
    "id": "1",
    "title": "2 pax monoambiente estandar",
    "Aloja": "2 Pasajeros",
    "strong": "- 2 Pasajeros -",
    "camas": "1 doble(s)",
    "tamaño": "20 m²",
    "entrada": "14:00hs",
    "salida": "10:00hs",
    "imageName": "../images/2paxmonoestandar/2paxmonoestandar1.jpg",
    "imageName1": "../images/2paxmonoestandar/2paxmonoestandar2.jpg",
    "imageName2": "../images/2paxmonoestandar/2paxmonoestandar3.jpg",
    "imageName3": "../images/2paxmonoestandar/2paxmonoestandar4.jpg",
    "imageName4": "../images/2paxmonoestandar/2paxmonoestandar5.jpg"
  },
  {
    "id": "2",
    "title": "2 pax 2 Ambientes",
    "strong": "- 2 Pasajeros | 2 Ambientes -",
    "Aloja": "2 Pasajeros",
    "camas": "1 doble(s)",
    "tamaño": "30 m²",
    "entrada": "14:00hs",
    "salida": "10:00hs",
    "imageName": "../images/dosp2amb/cab6-1.jpg",
    "imageName1": "../images/dosp2amb/cab6-2.jpg",
    "imageName2": "../images/dosp2amb/cab6-3.jpg",
    "imageName3": "../images/dosp2amb/cab6-4.jpg",
    "imageName4": "../images/dosp2amb/cab6-1.jpg"
  },
  {
    "id": "3",
    "title": "Monoambiente Jacuzzi Doble",
    "strong": "- 2 Pasajeros -",
    "Aloja": "2 Pasajeros",
    "camas": "1 doble(s)",
    "tamaño": "25 m²",
    "entrada": "14:00hs",
    "salida": "10:00hs",
    "imageName": "../images/monojacdoble/monojac1.jpg",
    "imageName1": "../images/monojacdoble/monojac2.jpg",
    "imageName2": "../images/monojacdoble/monojac3.jpg",
    "imageName3": "../images/monojacdoble/monojac4.jpg",
    "imageName4": "../images/monojacdoble/monojac5.jpg"
  },
  {
    "id": "4",
    "title": "4 pax Chica",
    "strong": "- 4 Pasajeros -",
    "Aloja": "4 Pasajeros",
    "camas": "2 individual(es), 1 doble(s)",
    "tamaño": "45 m²",
    "entrada": "14:00hs",
    "salida": "10:00hs",
    "imageName": "../images/4paxchica/4paxchica1.jpg",
    "imageName1": "../images/4paxchica/4paxchica2.jpg",
    "imageName2": "../images/4paxchica/4paxchica3.jpg",
    "imageName3": "../images/4paxchica/4paxchica4.jpg",
    "imageName4": "../images/4paxchica/4paxchica5.jpg"
  },
  {
    "id": "5",
    "title": "4 pax Grande",
    "strong": "- 4 Pasajeros -",
    "Aloja": "4 Pasajeros",
    "camas": "2 individual(es), 1 doble(s)",
    "tamaño": "57 m²",
    "entrada": "14:00hs",
    "salida": "10:00hs",
    "imageName": "../images/4paxgrande/4paxgrande1.jpg",
    "imageName1": "../images/4paxgrande/4paxgrande2.jpg",
    "imageName2": "../images/4paxgrande/4paxgrande3.jpg",
    "imageName3": "../images/4paxgrande/4paxgrande4.jpg",
    "imageName4": "../images/4paxgrande/4paxgrande5.jpg"
  },
  {
    "id": "6",
    "title": "4/5 pax Con Jacuzzi",
    "strong": "- 4/5 Pasajeros -",
    "Aloja": "4/5 Pasajeros",
    "camas": " 2 individual(es), 1 doble(s)",
    "tamaño": "70 m²",
    "entrada": "14:00hs",
    "salida": "10:00hs",
    "imageName": "../images/4-5paxconjacuzzi/4-5img-1.jpg",
    "imageName1": "../images/4-5paxconjacuzzi/4-5img-2.jpg",
    "imageName2": "../images/4-5paxconjacuzzi/4-5img-3.jpg",
    "imageName3": "../images/4-5paxconjacuzzi/4-5img-4.jpg",
    "imageName4": "../images/4-5paxconjacuzzi/4-5img-5.jpg"
  },
  {
    "id": "7",
    "title": "4/5 pax con Jacuzzi",
    "strong": "- 4/5 Pasajeros -",
    "Aloja": "4/5 Pasajeros",
    "camas": " 2 individual(es), 1 doble(s)",
    "tamaño": "70 m²",
    "entrada": "14:00hs",
    "salida": "10:00hs",
    "imageName": "../images/4-5paxconjacuzzi1/4-5img-1.jpg",
    "imageName1": "../images/4-5paxconjacuzzi1/4-5img-2.jpg",
    "imageName2": "../images/4-5paxconjacuzzi1/4-5img-3.jpg",
    "imageName3": "../images/4-5paxconjacuzzi1/4-5img-4.jpg",
    "imageName4": "../images/4-5paxconjacuzzi1/4-5img-5.jpg"
  },
  {
    "id": "8",
    "title": "5/6 pax estandar",
    "strong": "- 5/6 Pasajeros -",
    "Aloja": "5/6 Pasajeros",
    "camas": "4 individual(es), 1 doble(s)",
    "tamaño": "62 m²",
    "entrada": "14:00hs",
    "salida": "10:00hs",
    "imageName": "../images/5-6paxestandar/5-6img-1.jpg",
    "imageName1": "../images/5-6paxestandar/5-6img-2.jpg",
    "imageName2": "../images/5-6paxestandar/5-6img-3.jpg",
    "imageName3": "../images/5-6paxestandar/5-6img-4.jpg",
    "imageName4": "../images/5-6paxestandar/5-6img-5.jpg"
  }
]

export default data;